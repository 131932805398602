@import 'assests/sass/variables';

.edit-appbar-cois {
    position: relative !important;
    background-color: white !important;

    .MuiToolbar-root {
        min-height: 66px;
    }

    .arrow-icon {
        font-size: 2rem;
        color: #585858;
        opacity: .87;

    }

    .appbar-header {
        font-size: 1.25rem !important;
        font-family: 'Roboto-medium';
        color: #585858;
        margin-left: 4px;
        opacity: .87;
    }
}

.coi-edit-wrapper {
    padding: 1.6rem;
    padding-left: 11px;

    .edit-header {
        font-family: 'Roboto-medium';
        font-style: normal;
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: .15px;
        color: #212121;
        mix-blend-mode: normal;
        opacity: .87;
        margin-bottom: 1rem;
    }

    .sub-header {
        width: 100%;
        margin-top: 1.6rem;
        margin-bottom: 24px;
        font-family: 'Roboto-medium';
        font-style: normal;
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: .15px;
        color: #212121;
        mix-blend-mode: normal;
        opacity: .87;
        margin-bottom: 1rem;
    }

    .inner-sub-header {
        width: 100%;
        margin-bottom: 24px;
        font-family: 'Roboto-medium';
        font-style: normal;
        font-size: 1.1rem;
        line-height: 1.5rem;
        letter-spacing: .15px;
        color: #212121;
        mix-blend-mode: normal;
        opacity: .87;
        margin-bottom: 1rem;
    }

    .scroll-form {
        width: 100%;
        height: 700px;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1.4px solid #E2E2E1;
        border-radius: 8px;
        position: relative;

        .top-divider {
            margin-top: 2.49rem;
            color: #E2E2E1;
            height: 1.2px;
            opacity: 0.6;
        }

        .divider {
            color: #E2E2E1;
            height: 1.2px;
            opacity: 0.6;
        }

        .header-check-box {
            position: absolute;
            top: -9px;
            left: 3px;

            p {
                opacity: .87;
                font-family: 'Roboto';
                font-size: 14px;
                color: #212121;
                letter-spacing: .1px;
                position: absolute;
                top: 19px;
                width: 5rem;
                left: 2.6rem;
            }
        }
    }

    .checkbox-header {
        display: flex;
        align-items: center;
        padding-left: 4px;


        p {
            margin-left: -1.4rem;
            color: #12161B;
            font-size: 1.125rem;
            font-family: 'Roboto';
        }

    }

    .general-container {
        padding-left: 44px;
        padding-right: 10px;

        .aditional-insured {
            display: flex;
            align-items: center;

            .radio-button-label {
                color: #12161B;
                font-family: 'Roboto-Medium';
                font-size: 1.125rem;
                margin-top: 0.5rem;
            }

            p {
                margin-left: -1.4rem;
                color: #12161B;
                font-size: 1rem;
                font-family: 'Roboto';
            }

        }

        .toggle-button {
            font-family: 'Roboto-medium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: .1px;
            color: #00cfa1;
            cursor: pointer;
            margin: 1.5rem 0 1.4rem 0.2rem;

            &:hover {
                opacity: .76;
                text-decoration: underline;

            }
        }
    }
}