.table-divider {
    border-top: 1px solid #e4e4e4;
    margin-top: 24px;
    margin-bottom: 40px;
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
}

.header-area {
    width: auto !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.building-settings-templates {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    margin-top: 32px;
    margin-right: 30px;

    .header-area-empty {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }
}


.bottomDiv {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
}

.width-33 {
    width: 33.33%;
    border-right: 1px solid #E4E4E4;
}

.width-66 {
    height: 0px;
    width: 66.67%;
}

.border-botttom {
    border-top: 1px solid #E4E4E4;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: right;
    letter-spacing: 0.25px;
    color: rgba(33, 33, 33, 0.87);
    padding: 20px 0px;
    text-align: center;
    height: 70px;
    width: 100%;
}

.attach-div {
    padding: 15px 25px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;

    h5 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: #00CFA1;
        margin: 8px 0px 0px 8px;
        margin-bottom: 10px;
    }

    h6 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
        margin-left: 8px;
        margin-bottom: 2px;

        a {
            color: #4DC6FA;
        }
    }
}

.overview {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #000000;
}