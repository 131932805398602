@import 'assests/sass/variables';

.annual-budgets-wrapper {
    margin-top: 92px;
    margin-left: 1rem;
    width: 100%;

    .header-budget {
        opacity: .87;
        font-family: $font-primary;
        font-size: 20px;
        color: #212121;
        letter-spacing: .5px;
    }

    .desc-budget {
        font-family: $font-primary;
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 24px;
        color: #2f3030ad;
        margin-top: 1rem;
        margin-bottom: .5rem;

    }
}