@import "assests/sass/variables";

.navbar-container {
    z-index: 10;
    opacity: 2.9;
    min-height: 64px;
    display: -ms-flexbox;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    color: rgba(33, 33, 33, 0.87);
    //border-bottom: 1px solid rgba(33, 33, 33, 0.12);
    box-shadow: 0 1px 1px rgba(97, 97, 97, 0.14), 0 2px 1px -1px rgba(97, 97, 97, 0.12), 0 1px 3px rgba(97, 97, 97, 0.2);
}

.mobile-list {
    height: 100vh;
    opacity: 0.9;
    background: #ffffff;
    color: rgba(33, 33, 33, 0.87);
    border-bottom: 1px solid rgba(33, 33, 33, 0.12);

    .mobile-list-wrapper {
        margin-top: 2rem;
    }
}

.ad-main-navbar {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-side-nav {
        display: flex;
        align-items: center;

        .logo-wrapper {
            padding: 0 16px;
        }

        .logo-wrapper img {
            width: 120px;
            height: auto;

            &:hover {
                opacity: 0.8;
            }
        }

        .client-nav-links {
            display: flex;
            // align-items: center;
            height: 65px;
            margin-left: 1.5rem;
            // margin-top: 5px;

            .nav-link {
                padding: $padding-8 0px;
                // padding: 0px;
                display: flex;
                align-items: center;
                font-size: $_font-size-14;
                // line-height: 24px;
                text-decoration: none;
                font-weight: $font-weight-6;
                margin-right: 1.6rem;

                i {
                    margin-left: $margin-16;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }

                &:focus {
                    color: $color-grey;
                }
            }
        }
    }

    .nav-link-focused {
        padding-bottom: 3px !important;
        border-bottom: 3px solid #00CFA1;
        // border-bottom-left-radius: 8%;
        // border-bottom-right-radius: 8%;

        p {
            color: #00CFA1;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.04);
        }

        img,
        svg {
            color: #00CFA1;
        }

    }

    .right-side-nav {
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        justify-content: space-between;
        margin-right: 30px;
    }
}

.activeLinkClass {
    color: $color-green !important;
}

.dashboard-heading {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: rgba(33, 33, 33, 0.6);
    font-weight: 600;
    // color: #00CFA1;
    height: 40px;
    padding: 0px 6px;
}

.buildings-cum-popover {
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
    padding: $padding-8 0px;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }


    .workspace-heading {
        font-family: 'Roboto-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: rgba(33, 33, 33, 0.6);
        font-weight: 600;
        // color: #00CFA1;
        margin-right: 8px;
        padding-left: 6px;
        height: 40px;
    }

    .exc-bulding-button {
        margin-right: 0px !important;
        padding-right: 7px !important;
        font-family: 'Roboto-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: rgba(33, 33, 33, 0.6);
        font-weight: 600;
        // color: #00CFA1;
        margin-right: 8px;
        height: 40px;
    }

    img,
    svg {
        color: rgba(33, 33, 33, 0.6);
    }



    // .icon-wrapper {
    //     margin-top: -6px;
    //     margin-right: 18px;
    // }

    i {
        font-size: 12px;
        margin-right: 1rem;
        margin-left: 3px;
        margin-right: $margin-28;
        color: #212121;
        opacity: 0.6;
    }
}

// setting popver

.setting-popver {
    width: 280px;
    border-radius: 0;
    padding: 0.5rem 0;

    .option-bar {
        display: flex;
        align-items: center;
        width: 285px;
    }

    .span-checkbox {
        margin-left: 1rem;
    }

    .setting-option {
        font-size: 1rem;
        font-family: 'Roboto';
        font-weight: 400;
        line-height: 1.5rem;

        text-decoration: none;
        color: #12161B;
        cursor: pointer;
        border-radius: 0;
    }

    .account-setting-option-1 {
        background-color: #00CFA1;
        padding: 12px 10px;

        a {
            font-size: $_font-size-16;
            text-decoration: none;
            color: $color-black;
            border-radius: 0;
            color: #ffffff;
            cursor: pointer;

        }

    }

    .account-setting-option-2 {
        font-size: $_font-size-16;
        text-decoration: none;
        color: $color-black;
        padding: 12px 10px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: #F5F5F5;
        }
    }
}

.setting-popver-right {
    width: 155px;
    border-radius: 0;

    .option-bar {
        display: flex;
        align-items: center;
        width: 285px;
    }

    .span-checkbox {
        margin-left: 1rem;
    }

    .setting-option {
        font-size: 1rem;
        font-family: 'Roboto';
        font-weight: 400;
        line-height: 1.5rem;

        text-decoration: none;
        color: #12161B;
        cursor: pointer;
        border-radius: 0;
    }

    .account-setting-option-1 {
        background-color: #00CFA1;
        padding: 12px 10px;

        a {
            font-size: $_font-size-16;
            text-decoration: none;
            color: $color-black;
            border-radius: 0;
            color: #ffffff;
            cursor: pointer;

        }

    }

    .account-setting-option-2 {
        font-size: $_font-size-16;
        text-decoration: none;
        color: $color-black;
        padding: 12px 10px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: #F5F5F5;
        }
    }
}

// NOTIFICATION POPOVER

.notification-popover {
    width: 380px;
    height: max-content;
    max-height: 650px;

    .heading {
        display: flex;
        padding: 19px 22px 19px 18px;

        .head {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: black;
            mix-blend-mode: normal;
            opacity: 0.87;
        }

        .close-btn {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .notification-panel {

        .date-card {
            padding: 10px 22px 10px 18px;
            border-top: solid 1px rgba(31, 31, 31, 0.12);
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.5px;
            color: rgba(33, 33, 33, 0.6);
            text-transform: uppercase;
            font-size: 10px;
        }

        .property {
            padding: 5px 22px 5px 18px;
            border-top: solid 1px rgba(31, 31, 31, 0.12);
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: rgba(33, 33, 33, 0.6);
        }

        .history-body {
            padding: 10px 20px;

            .history-details {
                display: flex;
                flex-direction: row;

                .notification-logo {
                    padding-top: 5px;
                }

                .desc {
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.21875px;
                    color: rgba(33, 33, 33, 0.87);
                    padding: 2px 0px 0px 10px;
                    width: 100%;

                    .name {
                        display: contents;
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.21875px;
                        color: rgba(33, 33, 33, 0.87);
                        min-width: 130px;
                    }

                    .noti-link {
                        color: #00CFA1;
                    }
                }
            }

            .history-time {
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-style: normal;
                font-weight: 500;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.6);
                padding-left: 40px;
                padding-top: 4px;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }
        }
    }
}

.workspace-popver {
    min-width: 280px;
    min-height: 150px;

    .add-hover {
        padding: 10px 15px;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, .12);
        }
    }

    .recent {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: rgba(33, 33, 33, 0.6);
        padding: 0px 15px;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: #2196F3;
        border-radius: 4px;
        border-radius: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #FFFFFF;
    }

    .company-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: rgba(33, 33, 33, 0.87);
        margin-left: 12px;
    }

    hr {
        color: rgba(33, 33, 33, 0.38);
        margin: 10px 0px 0px 0px;
    }

}