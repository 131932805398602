.cois-email-template {
    margin-left: 10%;
    margin-top: 6rem;
    width: 34.4%;

    .sub-header {
        font-family: 'Roboto-medium';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: .5px;
        mix-blend-mode: normal;
        margin-bottom: 1rem;

    }

    .header {
        display: flex;
        align-items: center;

        icon {
            font-size: 12px;

        }

        p {
            font-family: 'Roboto-medium';
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: .5px;
            mix-blend-mode: normal;
            opacity: .87;
            margin-left: 1.3rem;
            margin-top: .3rem;
        }
    }

    .text-area {
        width: 100%;
        border-radius: 7px;
        border: 1px solid #dadad6;
        overflow-y: scroll;
        padding: 1rem;

        &:focus {
            border: 1px solid #00cfa1;

        }

        &:focus-visible {
            border: 1px solid #00cfa1 !important;
            outline: none;
        }

        &:active {
            border: 1px solid #00cfa1;

        }
    }

    .attachment-container {
        display: flex;
        align-items: center;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;

        i {
            color: #00cfa1;
            margin-left: .7rem;
            font-size: 1.11rem;
        }

        p {
            font-family: 'Roboto-medium';
            font-weight: 600;
            font-size: 14px;
            letter-spacing: .5px;
            mix-blend-mode: normal;
            padding-left: 1.1rem;
            color: #00cfa1;
            cursor: pointer;
        }
    }

    .file-listing {
        display: flex;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-items: center;
        padding: 0 1rem;

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: .25px;
            color: #212121;
            mix-blend-mode: normal;
            opacity: .6;
            cursor: pointer;
            word-break: break-all;
            padding-right: 2rem;
        }

        .icon {
            color: #7A7A7A
        }

    }
}