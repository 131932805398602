.invite-pages {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 72px;
    background-color: #ffffff;

    .container {
        .invite-content {
            margin-top: 123px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .invite-title {
                font-weight: bolder;
                letter-spacing: 0.2px;
                font-size: 48px;
                line-height: 64px;
                text-align: center;
                color: rgba(33, 33, 33, 0.87);
                margin-bottom: 24px;
            }

            .invite-subtitle {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 32px;
                /* identical to box height, or 133% */

                text-align: center;
                color: rgba(33, 33, 33, 0.87);
                margin-bottom: 24px;
            }

            .invite-description {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 32px;
                text-align: center;
                color: #616161;
                margin-bottom: 6px;

                a {
                    color: #00CFA1;
                    text-decoration: none;
                }
            }

            .input-wrap {
                display: flex;
                margin-top: 8px;
                flex-direction: column;

                .MuiTextField-root {
                    width: 350px;
                    height: 72px;
                    margin-top: 8px;
                    background-color: #ffffff
                }

                .MuiInput-root {
                    border: 1px solid #979797;
                    padding: 10px;
                    font-size: 20px
                }

                .MuiFormLabel-root {
                    color: rgba(33, 33, 33, 0.6);
                }

                .Mui-focused {
                    color: #00CFA1;
                }

                .MuiInputBase-input {
                    color: rgba(33, 33, 33, 0.6);
                }

                .MuiInputLabel-shrink {
                    color: #00CFA1;
                }
            }

            .button {
                width: 350px;
                height: 48px;
                margin-top: 18px;
                color: white;
                font-size: 16px;
                background-color: #00CFA1;
                line-height: 24px;
                box-shadow: 0px 0px 3px rgba(112, 112, 112, .3);
            }
        }
    }
}

.fields-wrapper {
    max-width: 400px;
    margin-top: 20px;
}