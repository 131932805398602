@import 'assests/sass/variables';

.cois-wrapper {
    width: 100%;
    margin: $margin-24;
    margin-top: 1rem;
    margin-top: 82px;

    margin-right: 39px;

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: $margin-8;

        .header-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.5rem;
            letter-spacing: .18px;
            color: rgba(33, 33, 33, .87);
        }

        .search-field {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            flex-wrap: wrap;

            .search-paper {
                width: 229px;
                height: 35px;
                border: 1px solid $color-grey-light;
                padding-left: 8px;
                padding-top: 2px;
                box-sizing: border-box;
                margin-right: 1.5rem;

                &:hover {
                    border: 2px solid $color-green;
                    width: 230px;
                    height: 36px;
                }

                &:focus {
                    border: 2px solid $color-green;
                    width: 230px;
                    height: 36px;
                }

                .search-icon {
                    color: $color-grey;
                    margin-left: 20px;
                    margin-bottom: 1px;
                }
            }
        }

        .buttson-wrapper {
            width: 130px;
            height: 36px;
        }
    }
}

.text-right {
    text-align: right !important;
}


.cois-popover {
    width: 200px !important;
    margin-right: 2.8rem;

    .chart-btn {
        letter-spacing: 0.00938em;
        padding: 12px 16px;
        font-size: 14px !important;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-primary;
        cursor: pointer;

        .edit-text {
            margin-left: .7rem;
            font-size: 15px;
            color: #2e2828;
        }

        .edit-icons {
            color: #383636;
        }

        .view-properties {
            margin-left: .7rem;
            font-size: 15px;
            color: #2e2828;
        }

        .delete-text {
            margin-left: .4rem;
            font-size: 16px;
            color: #2e2828;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.empty-array-wrapper {
    margin: auto;
    margin-top: 13rem;
    width: 11rem;
}

.status-values-cois {
    font-family: 'Roboto-medium';
    font-size: 14px;
}

.coi-details-sidesheet {
    position: fixed;
    top: 66px;
    right: 0;
    z-index: 2;
    display: block;
}

.detail-sidesheet-cois-paper {
    width: 322px;
    height: 100vh;
    padding: 1rem;
    padding-top: 2rem;
    padding-right: 1.6rem;
    margin-left: 1rem;

    .vendor-name {
        color: #21212199;
        font-family: 'Roboto-medium';
    }

    .icons {
        color: #21212199;
    }

    .left-name-container {
        p {
            font-size: 14px;
            color: #21212199;
            margin-bottom: 0.3rem;
        }
    }

    .right-name-container {
        p {
            font-size: 14px;
            color: rgba(33, 33, 33, .87);
            margin-bottom: 0.3rem;
        }
    }
}

.vendor-select {

    width: 195px !important;

    .MuiOutlinedInput-input {
        color: rgb(128, 128, 128) !important;
    }

    .MuiOutlinedInput-root {
        padding-right: 0 !important;
    }

    .MuiAutocomplete-clearIndicator {
        display: none;
    }

    fieldset {
        display: none;
    }
}

.tab-body.coil {
    width: 100%;
    float: left;
    padding: 0px 16px;
    margin-top: 20px;
}

.company-cate {
    display: flex;
    margin-bottom: 5px;
}

.title-cois {
    width: 140px;
    float: left;
    font-size: 14px;
    color: rgba(33, 33, 33, 0.6);
}

.value-cois {
    font-size: 14px;
    width: 140px;
    color: rgba(33, 33, 33, 0.87);
    font-weight: 400;
}