// COLOR VARIABLES

$color-green: #00cfa1;
$color-green-light: #50e3c2;
$color-green-extra-light: #a7f7e5;
$color-black: #333333;
$color-white: #ffffff;
$color-grey: #878787;
$color-grey-middle: #ababab;
$color-grey-light: #d8d8d8;
$color-grey-dark: #696969;
$color-grey-darker: #616161;
$color-disable: rgba(33, 33, 33, 0.38);
$color-text-title: rgba(33, 33, 33, 0.87);
$color-text-dark: rgba(0, 0, 0, 0.87);
$color-text-content: rgba(33, 33, 33, 0.6);
$color-light-grey: rgba(33, 33, 33, 0.6);
$color-hover-gray: rgba(0, 0, 0, 0.04);
$color-success: #1ac149;
$color-error: #f44a41;
$color-warning: #ffa800;

// FONT VARIABLES
$font-primary: "Roboto",
"Helvetica",
"Arial",
"sans-serif";
$font-secondary: "Roboto-Medium";
$font-Avenir: "Avenir";
$_font-size-12: 0.75rem;
$_font-size-14: 0.875rem;
$_font-size-16: 1rem;
$_font-size-18: 1.125rem;
$_font-size-20: 1.25rem;
$_font-size-22: 1.4rem;
$_font-size-24: 1.5rem;
$_font-size-34: 2.125rem;
$_font-size-36: 2.25rem;
$_font-size-48: 3rem;

// FONT Weight
$font-weight-4: 400;
$font-weight-5: 500;
$font-weight-6: 600;
$font-weight-7: 700;
$font-weight-8: 800;
$font-weight-9: 900;

// Border Radius
$border-radius-6: 6px;
$border-radius-12: 12px;
$border-radius-16: 16px;

// Line height
$_line-spacing-14: 0.875rem;
$_line-spacing-16: 1rem;
$_line-spacing-18: 1.125rem;
$_line-spacing-20: 1.25rem;
$_line-spacing-22: 1.375rem;
$_line-spacing-24: 1.5rem;
$_line-spacing-26: 1.625rem;
$_line-spacing-32: 2rem;

//Spacing
$padding-0: 0rem;
$padding-4: 0.25rem;
$padding-8: 0.5rem;
$padding-12: 0.75rem;
$padding-14: 0.875rem;
$padding-16: 1rem;
$padding-18: 1.125rem;
$padding-20: 1.25rem;
$padding-24: 1.5rem;
$padding-28: 1.75rem;
$padding-32: 2rem;
$padding-38: 2.4rem;
$padding-40: 2.5rem;
$padding-56: 3.5rem;

$margin-0: 0rem;
$margin-4: 0.25rem;
$margin-8: 0.5rem;
$margin-12: 0.75rem;
$margin-16: 1rem;
$margin-18: 1.125rem;
$margin-20: 1.25rem;
$margin-24: 1.5rem;
$margin-28: 1.75rem;
$margin-32: 2rem;
$margin-38: 2.4rem;
$margin-40: 2.5rem;