.auth-pages {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 72px;
    background-color: #ffffff;

    .container {
        .auth-content {
            margin-top: 123px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .auth-title {
                font-weight: 800;
                font-size: 48px;
                color: rgba(33, 33, 33, 0.87);
                line-height: 64px;
            }

            .auth-description {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 32px;
                text-align: center;
                color: #616161;
                padding-top: 20px;
            }

            form {
                display: flex;
                margin-top: 37px;
                flex-direction: column;
                align-items: center;
                min-height: 530px;

                .text-field {
                    display: flex;
                    margin-top: 8px;
                    flex-direction: column;

                    input {
                        height: 77px;
                        line-height: 77px;
                        font-size: 20px;
                        border-color: #979797;
                    }
                }

                .input-wrap {
                    display: flex;
                    margin-top: 8px;
                    flex-direction: column;

                    .MuiTextField-root {
                        width: 350px;
                        height: 72px;
                        margin-top: 8px;
                        background-color: #ffffff
                    }

                    .MuiInput-root {
                        border: 1px solid #979797;
                        padding: 10px;
                        font-size: 20px
                    }

                    .MuiFormLabel-root {
                        color: rgba(33, 33, 33, 0.6);
                    }

                    .Mui-focused {
                        color: #00CFA1;
                    }

                    .MuiInputBase-input {
                        color: rgba(33, 33, 33, 0.6);
                    }

                    .MuiInputLabel-shrink {
                        color: #00CFA1;
                    }
                }

                .button {
                    width: 350px;
                    height: 48px;
                    margin-top: 18px;
                    color: white;
                    font-size: 16px;
                    background-color: #00CFA1;
                    line-height: 24px;
                    box-shadow: 0px 0px 3px rgba(112, 112, 112, .3);
                }
            }

            .info-text {
                margin-top: 39px;
                font-size: 16px;
                color: rgba(33, 33, 33, 0.6);
                text-align: center;

                .click-text {
                    color: #00CFA1;
                }

                .loginback-text {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 32px;
                    text-align: center;
                    color: #00CFA1;
                }
            }

            // .error-text {
            // 	font-size: 12;
            // 	font-family: $font-primary;
            // 	color: $color-red-dark;
            // 	line-height: 16;
            // }
        }
    }

    .footer {
        border-top: 1px solid #DDD;

        .container {
            max-width: 1140px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 25px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .footer-text {
                font-size: 16px;
                line-height: 32px;
                color: rgba(33, 33, 33, 0.6);
                text-transform: uppercase;
            }

            .footer-text-email {
                font-size: 16px;
                line-height: 32px;
                color: rgba(33, 33, 33, 0.6);
            }
        }
    }
}

.rfp-styled-p {
    text-align: start;
    font-size: 14px;
    color: #00CFA1;
    margin-top: 10px;
    width: 100%;
}

.sigCanvas {
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    width: 100%;
}

.refreshSign {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    position: relative;
    top: 30px;
    width: 100%;
}

.SaveDivBtn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-top: 3px;
    /* identical to box height, or 143% */
    text-align: center;
    letter-spacing: 0.1px;
    width: 40px;

    /* Base / Surface */
    color: #FFFFFF;
    background: #00CFA1;
    border-radius: 5px;
    cursor: pointer;
}

.rfpsuccess-div {
    margin: 12% 0% 15% 0%;
    width: 368px;
    height: 272px;
    background: white;

    p {
        font-weight: 500;
        font-size: 20px;
        color: #263238;
    }

    h6 {
        font-size: 16px;
        color: rgba(33, 33, 33, 0.6);
        margin: 10px 0px;
    }
}

.formError-div {
    margin: 12% 0% 15% 0%;
    width: 368px;
    height: 272px;
    background: white;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

        h6 {
            font-size: 20px;
            color: rgba(33, 33, 33, 0.6);
            margin: 10px 0px;
        }
    }
}

.survey-top {
    border-top: 1px solid #E4E4E4;
    margin-top: 1rem;
}

.auth-pages-footer {
    padding: 45px;
    border-top: 1px solid #ddd;
}