.workspace-wrapper {
    margin-top: 82px;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.18px;
            color: rgba(33, 33, 33, 0.87);
            mix-blend-mode: normal;
        }
    }

    .workspace-card {
        margin-top: 20px;
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.14), 0px 3px 1px -2px rgba(97, 97, 97, 0.12), 0px 1px 5px rgba(97, 97, 97, 0.2);
        border-radius: 8px;
        height: 200px;
        position: relative;
        min-width: 200px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: #00CFA1;
            border-radius: 8px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.15px;
            color: #FFFFFF;
        }

        .company-name {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: rgba(33, 33, 33, 0.87);
            margin-top: 18px;
        }

        .workspace-members {
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 25px;
            letter-spacing: 0.4px;
            color: rgba(33, 33, 33, 0.6);
            position: absolute;
            bottom: 10px;
            flex-wrap: wrap;

            .MuiAvatar-root {
                height: 30px;
                width: 30px;
                margin-right: 6px;
            }
        }
    }
}