@import 'assests/sass/variables';


.signup-container {
    height: 100vh;
    margin-top: 120px;

    .signup-content h1 {
        font-size: $_font-size-48;
        color: rgba(33, 33, 33, 0.87);
        line-height: 64px;
        margin-bottom: $margin-38;
        font-weight: $font-weight-7;
    }

    .signup-content form {
        width: 35%;
        margin: 0 auto;
    }

    .signup-content .login-link-area {
        margin-top: $margin-38;
    }
}

.forget-button {
    font-size: $_font-size-14;
    line-height: 32px;
    text-align: center;
    color: $color-green;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $color-green;
    }
}