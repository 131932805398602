.preview-container {
    height: 128px;
    border: 1.4px dashed rgba(0, 0, 0, .16);
    width: 100%;
    text-align: center;
    padding-top: 2.4rem;
    border-radius: 4px;
    color: #21212199;
}

.thumbs-container {
    display: flex;
    border: 1.4px solid rgba(0, 0, 0, .15);
    margin-top: 12px;
    border-radius: 4px;
}

.thumb {
    display: inline-flex;
    border-radius: 4px;
    width: 60px;
    height: 56px;
    padding: 6px;
    padding-left: 11px;
}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden,
}

.img-style {
    display: block;
    width: auto;
    height: 100%;
}

.thumb-icon{
    color: #7A7A7A;
    margin-right:0.6rem;
}