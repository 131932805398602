@import 'assests/sass/variables';

.vendor-coi-wrapper {
    width: 100%;
    margin: $margin-24;
    margin-top: 92px;

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: $margin-8;

        h6 {
            font-size: $_font-size-18;
            color: $color-text-title;
            font-family: $font-primary;
            margin-bottom: $margin-16;
        }

        .button-wrapper {
            width: 256px;
            height: 36px;
        }
    }
}

.cio-form-wrapper {
    margin-top: 92px;
}

.cio-form-heading {
    font-size: 20px;
    color: $color-text-title;
    font-family: $font-primary;
    padding-bottom: 20px;
}

.cio-section-heading {
    color: $color-text-title;
    font-family: $font-primary;
    font-size: $_font-size-18;
    margin-bottom: 5px;
    margin-top: 8px;
}

.cio-add-btns {

    h4 {
        color: #00cfa1;
        cursor: pointer;
        font-family: $font-primary;
        font-size: $_font-size-18;
        padding-top: $padding-12;

        &:hover {
            color: #979797;
        }
    }
}

.cio-info-box {
    p {
        color: $color-text-title;
        font-family: $font-primary;
        font-size: $_font-size-18;
        margin-bottom: 5px;
        margin-top: 8px;
    }

    h6 {
        color: #979797;
        font-size: $_font-size-18;
        font-family: $font-primary;
        margin: 8px 0 15px 2px;
    }

}

.cio-checkbox-wrapper {
    display: flex;
    flex-direction: column;

    .MuiFormControlLabel-root {
        padding: 0 !important;
        padding-left: 10px !important;
    }
}

.cio-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: right;
    margin-top: $margin-38;

    .btn-wrapper {
        width: 182px;
    }
}

.start-doller-sign {
    margin-top: $margin-18;
    margin-right: $margin-12;
    color: $color-text-content;
}

.tooltipData {
    position: relative;

    p {
        min-width: max-content;
        position: absolute;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.tooltips {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltips .tooltiptexts {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltips .tooltiptexts:hover {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}