@import 'assests/sass/variables';

.vendor-contacts-wrapper {
    width: 100%;
    margin: $margin-24;
    margin-top: 82px;

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: $margin-8;

        h6 {
            font-size: $_font-size-18;
            color: $color-text-title;
            font-family: $font-primary;
        }

        .button-wrapper {
            width: 130px;
            height: 36px;
        }
    }

    .not-found-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;

        h5 {
            margin-bottom: 8px;
        }

        h6 {
            margin-left: 6px;
            margin-bottom: 14px;
            color: $color-text-content;
        }

        .file-btn-wrapper {
            display: flex;
            align-items: center;
            margin-right: 15px;
            cursor: pointer;

            p {

                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.1px;
                color: #00CFA1;
                margin-left: 8px;
            }
        }
    }
}

.text-right {
    text-align: right !important;
}

.chart-accounts-popover {
    width: 100px !important;

    .chart-btn {
        letter-spacing: 0.00938em;
        padding: 12px 16px;
        font-size: 14px !important;
        line-height: 24px;
        justify-content: flex-end;
        font-family: $font-primary;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.rfp-popover {
    width: 150px !important;

    .chart-btn {
        letter-spacing: 0.00938em;
        padding: 12px 16px;
        font-size: 14px !important;
        line-height: 24px;
        justify-content: flex-end;
        font-family: $font-primary;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.text-transform-none {
    text-transform: none !important;
}

.upload-button {
    padding: 8px 10px;
    background: #e4e4e4;
    border-radius: 6px;

    span {
        color: gray;
    }
}

.primary-color {
    color: #00CFA1 !important;
}