@import 'assests/sass/variables';


.forget-container {
    height: 100vh;

    .forget-content h1 {
        font-size: $_font-size-48;
        color: rgba(33, 33, 33, 0.87);
        line-height: 50px;
        margin-bottom: $margin-38;
        font-weight: $font-weight-7;
        font-family: $font-Avenir;
        color: #3E3E3E;
    }

    .forget-content form {
        margin-top: $margin-38;
    }
}